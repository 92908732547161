.contact {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: center;
  padding-top: 30%;
  padding-bottom: 50px;
  background-color: black;
  flex-basis: 100vh;
  @media (min-width: 768px) {
    padding-top: 100px;
    flex-basis: 60vh;
  }

  & .info {
    opacity: 0;
    margin-bottom: 20px;
    flex-basis: 40vh;
    height: 20vh;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    color: black;
    border: 3px solid #cc2229;
    @media (min-width: 768px) {
      flex-basis: 20%;
      height: 20vh;
    }

    & h2 {
      text-transform: uppercase;
      font-size: 1rem;
    }
    & span {
      max-width: 90%;
      text-align: center;
    }
    & .svg-inline--fa {
      font-size: 3rem;
      color: #cc2229;
    }
  }
}

.contact-map {
  display: none;
  @media (min-width: 768px) {
    display: block;
    height: 40vh;
  }
  
}


.contact.active {
  .contact-location {
    opacity: 1;
    animation: bounceIn 1s 0.25s both;
  }
  .contact-phone {
    opacity: 1;
    animation: bounceIn 1s 0.5s both;
  }
  .contact-email {
    opacity: 1;
    animation: bounceIn 1s 0.75s both;
  }
  .contact-work-time {
    opacity: 1;
    animation: bounceIn 1s 1s both;
  }
}
