.about__content {
  flex-basis: 100%;
  display: flex;
  flex-wrap: wrap;
  @media (min-width: 768px) {
    height: 100vh;
  }
  overflow: hidden;
}

.about__photo {
  display: none;
  @media (min-width: 768px) {
    display: block;
    flex-basis: 50%;
    height: 50%;
  }
}
.about__info {
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;
  @media (min-width: 768px) {
    flex-basis: 50%;
    height: 50%;
  }
}

.about__title {
  opacity: 0;
  color: white;
  margin-bottom: 20px;
  max-width: 90%;
  @media (min-width: 768px) {
  }
  font-size: 2rem;
}
.about__text {
  opacity: 0;
  text-align: left;
  width: 70%;
  font-size: 1.2rem;
}

.about__advantages {
  flex-basis: 100%;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 768px) {
    height: 50%;
  }
}
.about__list {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-basis: 70%;
  height: 100%;
  flex-wrap: wrap;
  @media (min-width: 768px) and (max-width: 1024px) {
    flex-basis: 100%;
  }
  & li {
    opacity: 0;
    background-color: #fff;
    height: 65vw;
    flex-basis: 70vw;
    list-style: none;
    border: 4px solid #cc2229;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & .about__text {
      text-transform: uppercase;
      font-weight: bold;
      text-align: center;
    }
    & .about__logo {
      color: #cc2229;
      font-size: 4rem;
      margin-bottom: 20px;
    }
    @media (max-width: 767px) {
      margin-bottom: 50px;
    }
    @media (min-width: 768px) {
      height: 50%;
      flex-basis: 30%;
    }
  }
}

.about.active {
  .about__title {
    opacity: 1;
    animation: fadeInRight 1s;
  }
  .about__text {
    opacity: 1;
    animation: fadeIn 1s 0.5s both;
  }

  .about__exp {
    opacity: 1;
    animation: flipInX 1s 1s both;
  }

  .about__prof {
    opacity: 1;
    animation: flipInX 1s 1.5s both;
  }

  .about__quality {
    opacity: 1;
    animation: flipInX 1s 2s both;
  }
}
