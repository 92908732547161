.nav {
  //logo
  &-logo {
    height: 80px;
    width: 100vw;
    position: fixed;
    top: 0;
    padding: 0 20px;
    z-index: 4;
    display: flex;
    flex-basis: 80px;
    justify-content: space-between;
    align-items: center;
    transition: 0.5s;
    &:hover {
      background-color: rgba(52, 55, 54, 0.5);
    }
    &__hamburger {
      color: white;
      font-size: 3rem;
      cursor: pointer;
      transition: 0.5s;
      z-index: 4;
      @media (min-width: 768px) {
        display: none;
      }
    }
    &__logo {
     
      z-index: 4;
      
    }

    &.logo-modify {
      background-color: #cc2229;
    }
    &__open {
      display: none;
      @media (min-width: 768px) {
        display: block;
        flex-basis: 40%;
        color: white;
        text-align: right;
        padding-right: 10px;
      }
    }
  }
  //contact
  &-contact {
    position: fixed;
    bottom: 0;
    height: 50px;
    width: 100vw;
    display: flex;
    z-index: 10;
    flex-basis: 50px;
    @media (min-width: 768px) {
      display: none;
    }
    &__email {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
      text-decoration: none;
      background-color: #cc2229;
      text-transform: uppercase;
      font-weight: bold;
      color: white;
      &-text {
        margin-left: 5px;
      }
    }
    &__phone {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
      text-decoration: none;
      background-color: #f6d330;
      text-transform: uppercase;
      font-weight: bold;
      color: black;
      &-text {
        margin-left: 5px;
      }
    }
  }

  & .menu {
    background-color: rgba(52, 55, 54, 0.5);
    z-index: 2;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 100%;
    transition: 0.5s;
    @media (min-width: 768px) {
      position: static;
      height: 100%;
      background-color: rgba(52, 55, 54, 0);
    }
    &.active {
      left: 0;
    }
    &__pages {
      margin-top: 100px;
      display: flex;
      flex-direction: column;
      @media (min-width: 768px) {
        flex-direction: row;
        margin-top: 0;
        align-items: center;
        height: 100%;
        padding-left: 50px;
      }
      & li {
        padding-left: 10px;
        flex-basis: 50px;

        font-size: 2rem;
        font-weight: bold;
        border-top: 1px solid white;
        display: flex;
        align-items: center;
        display: flex;
        @media (min-width: 768px) {
          flex-basis: 20%;
          text-align: center;
          border-top: none;
          transition: 0.3s;
          font-size: 1.5rem;
          height: 100%;
        }
        &:hover {
          cursor: pointer;
          background-color: rgba(52, 55, 54, 1);
          @media (min-width: 768px) {
            background-color: rgba(52, 55, 54, 0);
            border-bottom: 2px solid white;
          }
        }

        & a {
          color: white;
          text-decoration: none;
          display: block;
          flex-basis: 100%;
        }
      }
    }
  }
}
