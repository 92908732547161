.service-list {
  width: 70%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  @media (min-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    height: 60vh;
    width: 100%;
  }
  @media (min-width: 1024px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 70%;
  }
  & li {
    opacity: 0;
    position: relative;
    &::after {
      left: 0;
      content: "";
      position: absolute;
      display: block;
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.7);
    }
    display: flex;
    align-items: center;
    padding-left: 10px;
    list-style: none;
    flex-basis: 15%;
    width: 95%;
    text-transform: uppercase;
    font-weight: bold;
    background-repeat: no-repeat;
    background-position: center;

    transition: 0.5s;
    @media (min-width: 768px) {
      flex-basis: 40%;
      height: 150px;
    }
    @media (min-width: 1024px) {
      flex-basis: 30%;
      height: 150px;
    }
    &:hover {
      background-position: right center;
      & span::after {
        width: 100%;
      }
    }

    & span {
      position: relative;
      font-size: 1.2rem;
      z-index: 2;
      padding: 0 10px;
      color: white;
      &::after {
        bottom: 0;
        left: 0;
        position: absolute;
        content: "";
        width: 0%;
        height: 2px;
        transition: 0.3s;
        background-color: #cc2229;
      }
    }
  }

  &__suspension-systems {
    background-image: url("../img/service-img/photo_4-xsmall.jpg");
  }
  &__gear-replacement {
    background-image: url("../img/service-img/photo_3-xsmall.jpg");
  }
  &__services {
    background-image: url("../img/service-img/photo_2-xsmall.jpg");
  }
  &__engine-diagnostics {
    background-image: url("../img/service-img/photo_1.jpg");
  }
  &__computer-diagnostics {
    background-image: url("../img/service-img/photo_5-xsmall.jpg");
  }
  &__servicing {
    background-image: url("../img/small/engine-small.jpg");
  }
}

.service-header {
  background-image: url("../img/small/engine-small-second.jpg");
  height: 50vh;
  position: relative;
  @media (min-width: 768px) {
    background-image: url("../img/large/engine-large-second.jpg");
    height: 40vh;
  }

  &::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
}
.service-content {
  display: flex;
  position: absolute;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  @media (min-width: 768px) {
    font-size: 2rem;
  }
}
.service-text {
  width: 80%;
  text-align: center;
  margin-top: 10px;
}
.service.active {
  opacity: 1;
  .service-list__suspension-systems {
    animation: bounceIn 1s 0.75s both;
  }
  .service-list__gear-replacement {
    animation: bounceIn 1s 1s both;
  }
  .service-list__services {
    animation: bounceIn 1s 1.25s both;
  }
  .service-list__engine-diagnostics {
    animation: bounceIn 1s 0.5s both;
  }
  .service-list__computer-diagnostics {
    animation: bounceIn 1s 0.25s both;
  }
  .service-list__servicing {
    animation: bounceIn 1s 1.5s both;
  }
}
