* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
button {
  border: none;
  width: auto;
  overflow: visible;
  background: transparent;
}
