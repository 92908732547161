.home {
  display: flex;
  flex-direction: column;
}

//header

.header {
  flex-basis: 100vh;

  overflow: hidden;
  position: relative;
  display: flex;

  justify-content: center;
  align-items: center;
  &-content {
    position: absolute;
    z-index: 0;

    height: 40%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    @media (min-width: 768px) {
      height: 60%;
      width: 90%;
    }
    &__title {
      text-align: center;
      max-width: 80%;
      color: white;
      text-transform: uppercase;
      font-size: 1.5rem;
      @media (min-width: 768px) {
        font-size: 3rem;
        max-width: 100%;
        width: 100%;
        text-align: left;
        margin-left: 0;
      }
      animation: fadeInLeft 1s;
    }
    &__button {
      background-color: #cc2229;
      height: 80px;
      width: 280px;
      color: white;
      text-transform: uppercase;
      font-size: 1.5rem;
      font-weight: bold;

      cursor: pointer;
      @media (min-width: 768px) {
        align-self: flex-end;
      }
      animation: fadeIn 1s 1s both;
    }
    &__images {
      display: flex;
      justify-content: space-around;
      width: 60vw;
      @media (min-width: 768px) {
        align-self: flex-start;
        justify-content: flex-start;
      }
    }
    &__shell {
      height: 30vw;
      width: 30vw;
      max-width: 150px;
      max-height: 150px;
      @media (min-width: 768px) {
        height: 150px;
        width: 150px;

        margin-right: 20px;
      }
      animation: bounceIn 1s 0.5s both;
    }
    &__magneti-marelli {
      height: 30vw;
      width: 30vw;
      max-width: 150px;
      max-height: 150px;
      background-color: #fff;
      @media (min-width: 768px) {
        height: 150px;
        width: 150px;
      }
      animation: bounceIn 1s 0.75s both;
    }
    &__logo{
      width:100%;
      height:100%;
      display:flex;
      justify-content:center;
      align-items:center;
    }
  }
}

.slider {
  z-index: -1;
  &::after {
    content: "";
    position: absolute;
    display: block;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
  }
  display: flex;
  position: absolute;
  transition: 2s;
  left: 0;
  &__photo {
    background-position: center center;
    background-attachment: fixed;
    background-size: cover;
    min-width: 100vw;
    width: auto;
    height: 100vh;
  }
}

//service
.service {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../img/bgc/beanstalk.png");
  @media (min-width: 768px) {
    height: 60vh;
  }
}
//about

.about {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../img/bgc/white_carbonfiber.png");
  @media (min-width: 768px) {
    flex-basis: 100vh;
  }
}

//contact

.contact {
  flex-basis: 100vh;
  background-color: white;
  @media (min-width: 768px) {
    flex-basis: 40vh;
  }
}
